<template>
  <div>

    <div class="form-group search-bar d-flex justify-content-center mt-2 mb-2">
      <span class="input-group-addon" style="background-color:#FFFFFF;"><span class="glyphicon glyphicon-search"></span></span>
      <input type="text" class="form-control" id="inputGroupSuccess1" placeholder="Search..." v-model="searchTerm">
    </div>

    <div class="table-responsive">
      <table class="card-table table">
        <thead id="search-bar">
        <tr>
          <th :style="{ width: headerWidth + '%' }" v-for="(header, index) in tableHeaders" :key="index" @click="header && updateFilter('sort', header.toLowerCase())" :data-type="header" class="filter-header">
            <span v-if="header">{{ header }}</span>
            <img v-if="header" :id="header.replace(/ /g, '_').toLowerCase()" alt="filter arrow" :src="arrows.default">
          </th>
        </tr>
        </thead>
      </table>
    </div>

  </div>
</template>

<script>

import { FilterService } from '@/common/services/filter.service';
import _ from 'lodash';

export default {
  name: 'RansackFiltering',
  props: {
    tableHeaders: Array,
    headerWidth: Number,
    searchParam: String,
    includeBlankHeader: Boolean,
    ransackableSearchTerm: String,
  },
  data() {
    return {
      filters: FilterService.defaultData(),
      searchActive: false,
      searchTerm: '',
      arrows: {
        asc: 'http://dam.alterisgroup.net/customer_assets/asset-b66c9be0-9f09-475e-b65b-0386351d4ad0/release',
        desc: 'http://dam.alterisgroup.net/customer_assets/asset-79a21b5f-10e7-45f1-8216-a1043d40d26b/release',
        default: 'http://dam.alterisgroup.net/customer_assets/asset-3ff1fb06-268c-4942-8e09-af1685f22993/release',
      },
      sortedBy: '',
    };
  },
  methods: {
    updateFilter(type, column, data = null) {
      this.filters = FilterService.controller(type, this.filters, column.replace(/ /g, '_'), data);

      this.updateHeaderImage();

      this.$emit('onChange', this.filters);
    },
    updateHeaderImage() {
      const headerId = this.filters.s.split(' ')[0];
      const headerSort = this.filters.s.split(' ')[1];
      const img = document.getElementById(headerId);
      const lastSortImg = document.getElementById(this.sortedBy);

      if (lastSortImg && lastSortImg.id !== img.id) {
        lastSortImg.src = this.arrows.default;
      }

      this.sortedBy = headerId;
      if (headerSort === '') {
        img.src = this.arrows.default;
      } else {
        img.src = this.arrows[headerSort];
      }
    },
  },
  // Add a watcher for the search term, debounce so we don't make a request every single character change, and then emit back to the index to reload with search params
  watch: {
    searchTerm: _.debounce(function debounceSearch(val) {
      this.filters = FilterService.controller('filter', this.filters, this.searchParam, val);
      this.$emit('onChange', this.filters);
    }, 250),
    // This is used for the favourites checks
    ransackableSearchTerm: _.debounce(function debounceSearch(val) {
      this.filters = FilterService.controller('filter', this.filters, this.searchParam, val);
      this.$emit('onChange', this.filters);
    }, 50),
  },
};
</script>

<style lang="scss" scoped>
  .search-bar {
    width: 30%;
    margin: 0 auto;
    padding: 2px 10px;
  }
  .table {
    margin: 0;
    padding: 0;
    border-color: $white;
  }
  .filter-header {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    text-align: left;
    float: left;
    border-bottom: none !important;
    border-top: none !important;
    &:last-of-type {
      cursor: default;
    }
  }
  #search-bar {
    border-top: 0.5px solid rgba(0, 0, 0, 0.125);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.125);
  }
</style>
