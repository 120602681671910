<template>
  <div v-if="totalRows >= perPage" class="card-header">
    <div class="d-flex justify-content-center">
      <div class="p-1">
        <b-pagination align="center" size="md" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="changePage"></b-pagination>
      </div>
      <div class="p-1">
        <select id="per_page_select" class="custom-select custom-select-md" v-model="perPage">
          <option :value="5">5 per page</option>
          <option :value="10">10 per page</option>
          <option :value="25">25 per page</option>
          <option :value="50">50 per page</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    totalRows: Number,
    numOfRecords: Number,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.numOfRecords,
    };
  },
  methods: {
    changePage(page) {
      this.$emit('onChange', page, this.perPage);
    },
  },
  watch: {
    numOfRecords() {
      this.perPage = this.numOfRecords;
    },
    perPage() {
      this.$emit('onChange', this.currentPage, this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
